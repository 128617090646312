<template lang="pug">
	section#form
		.container
			form(@submit.prevent="send")
				.input-wrapper._100
					.required
					.input
						input(type="text", v-model="form['Empresa']", placeholder="Empresa")
				.input-wrapper._100
					.required *
					.input
						input(type="text", v-model="form['Nome']", placeholder="Nome" required)
				.input-wrapper._100
					.required *
					.input
						input(type="text", v-model="form['Endereço']", placeholder="Endereço" required)
				.group
					.input-wrapper._30
						.required *
						.input
							input(type="text", v-model="form['CEP']", v-mask="['#####-###']" placeholder="CEP")
					.input-wrapper._20
						.select
							select(v-model="form['Estado']", @change="getCities" required)
								option(disabled selected :value="null") UF
								option(v-for="state in ufs" :value="state") {{state}}
					.input-wrapper._50
						.select
							select(v-model="form['Cidade']" required)
								option(disabled selected :value="null") Cidade
								option(v-for="city in cities" :value="city") {{city}}
				.group
					.input-wrapper._30
						.required *
						.input
							input(type="text", v-model="form['DDD']", placeholder="DDD" v-mask="['(##)']" required)
					.input-wrapper._70
						.input
							input(type="text", v-model="form['Telefone']", placeholder="Telefone" v-mask="['#####-####','####-####']" required)
				.input-wrapper._100
					.required *
					.input
						input(type="email", v-model="form['E-mail']", placeholder="Email" required)
				.input-wrapper._100(v-if="hasManifestacao")
					.required
					.input
						input(type="text", v-model="form['Manifestação']", placeholder="Manifestação")
				.input-wrapper._100(v-if="hasMotivo")
					.required
					.input
						input(type="text", v-model="form['Motivo']", placeholder="Motivo")
				.input-wrapper._100(v-if="hasProduto")
					.required *
					.input
						input(type="text", v-model="form['Produtos']", placeholder="Produto" required)
				.group(v-if="hasVeiculo")
					.input-wrapper._50
						.required
						.input
							input(type="text" v-model="form['Placa']", placeholder="Placa do Veículo")
					.input_wrapper._50
						.input
							input(type="number" v-model="form['Frota']", placeholder="Número da Frota")
				.group(v-if="hasVeiculo")
					.input-wrapper._50
						.required
						.input
							input(
								type="text" 
								v-model="form['Ocorrência']" 
								placeholder="Data da Ocorrência" 
								:max=" new Date().toISOString().split('T')[0]"
								@click="changeInputType('Ocorrência')")
					.input_wrapper._50
						.input
							input(type="time" v-model="form['Horário']")
				.group(v-if="hasLote")
					.input-wrapper._33
						.required
						.input
							input(type="text", v-model="form['Lote']", placeholder="Lote")
					.input-wrapper._33
						.input
							input(
								ref="Fabricação"
								type="text"
								v-model="form['Fabricação']"
								placeholder="Data Fabr."
								@click="changeInputType('Fabricação')"
							)
					.input-wrapper._33
						.input
							input(
								ref="Validade"
								type="text"
								v-model="form['Validade']"
								placeholder="Data Valid."
								@click="changeInputType('Validade')"
							)
				.input-wrapper._100
					.required *
					.textarea
						textarea(rows="6" placeholder='Mensagem' v-model="form['Mensagem']" required)
				.input-wrapper._100
					.required
					.row
						label.file
							.img-wrapper
								img(src="@images/paginas/fale-conosco/icons/anexar.png")
							span {{ isFile ? file.name : 'Anexar Arquivo' }}
							input(type="file" @change="saveFile($event)")
							.tooltip
								p
									| Tamanho Máx. 25mb
									br
									| Enviar somente um arquivo.
						.button
							button(type="submit", :disabled="sending") {{ sending ? 'Enviando...' : 'Enviar' }}
				.message(v-if="displayMessage")
					span Enviado com sucesso!
				.message(v-if="errorMessage")
					span Falha ao enviar o formulário.
				.aside-form
					.required *
					.box Preenchimento obrigatório
</template>

<script>
import { mask } from 'vue-the-mask'
import { props } from '@/mixins/component'

const FORM = {
	'Empresa': '',
	'Nome': '',
	'CEP': '',
	'Endereço': '',
	'E-mail': '',
	'Telefone': '',
	'DDD': '',
	'Mensagem': '',
	'Estado': null,
	'Cidade': null,
}

// const FORMold = [
// 	{
// 		name: 'Empresa',
// 		value: ''
// 	},
// 	{
// 		name: 'Nome',
// 		value: ''
// 	},
// 	{
// 		name: 'Cep',
// 		value: ''
// 	},
// 	{
// 		name: 'Endereço',
// 		value: ''
// 	},
// 	{
// 		name: 'E-mail',
// 		value: ''
// 	},
// 	{
// 		name: 'Telefone',
// 		value: ''
// 	},
// 	{
// 		name: 'DDD',
// 		value: ''
// 	},
// 	{
// 		name: 'Mensagem',
// 		value: ''
// 	},
// 	{
// 		name: 'Estado',
// 		value: ''
// 	},
// 	{
// 		name: 'Cidade',
// 		value: ''
// 	},
// ]

export default {
    name: "section-form",
	directives: { mask },
	props,
	data() {
		return {
			form: { ...FORM },
			displayMessage: false,
			errorMessage: false,
			sending: false,
			isFile: false,
			file: null,
			ufs: [],
			cities: [],
		}
	},
	computed: {
		hasLote() {
			return this.contents.content.lote
		},
		hasManifestacao() {
			return this.contents.content.manifestacao
		},
		hasMotivo() {
			return this.contents.content.motivo
		},
		hasProduto() {
			return this.contents.content.produto
		},
		hasVeiculo() {
			return this.contents.content.veiculo
		},
	},
	mounted() {
		this.getUfs()
		this.addFields()
	},
	methods: {
		send() {
			this.sending = true
			this.errorMessage = false
			this.displayMessage = false
			let route = `leads`
			let data = {
				subject: this.contents.content.subject || 'Fale Conosco',
				origin: 'Coamo Institucional',
				form: [],
				leadEmail: this.contents.content.email || process.env.VUE_APP_CONTACT
			}
			for (const [ key, value ] of Object.entries(this.form)) {
				data.form.push({
					name: key,
					value,
				})
			}
			if (this.file) {
				data = this.parseForm(data)
				route += `/file`
			}

			this.$axios
				.post(route, data)
				.then(response => {
					this.displayMessage = true
					this.form = { ...FORM }
					this.addFields()
				}).catch(error => {
					this.errorMessage = true
				}).finally(() => {
					setTimeout(() => {
						this.sending = false
						this.isFile = false
					}, 4000)
				})
		},
		getUfs() {
			this.$axios
				.get('ibge-api')
				.then(response => this.ufs = response.data)
		},
		getCities() {
			this.$axios
				.get(`ibge-api/${this.form['Estado']}`)
				.then(response => this.cities = response.data)
		},
		parseForm(form) {
			const data = new FormData()
			Object.keys(form).forEach(key => {
				if (key == 'form')
					data.append(key, JSON.stringify(form[key]))
				else
					data.append(key, form[key])
			})
			data.append('file', this.file)
			return data
		},
		addFields() {
			if (this.hasLote)
				// this.form = [ ...this.form, { name: 'Lote', value: ''}, { name: 'Fabricacao', value: ''}, { name: 'Validade', value: ''}  ]
				this.form = { ...this.form, 'Lote': '', 'Fabricação': '', 'Validade': '' }
			if (this.hasManifestacao)
				// this.form = [ ...this.form, { name: 'Manifestação', value: ''} ]
				this.form = { ...this.form, 'Manifestação': '' }
			if (this.hasMotivo)
				// this.form = [ ...this.form, { name: 'Motivo', value: ''} ]
				this.form = { ...this.form, 'Motivo': '' }
			if (this.hasProduto)
				// this.form = [ ...this.form, { name: 'Produtos', value: ''} ]
				this.form = { ...this.form, 'Produtos': '' }
			if (this.hasVeiculo)
				// this.form = [ ...this.form, { name: 'Frota', value: ''}, { name: 'Ocorrência', value: ''}, { name: 'Horário', value: ''} ]
				this.form = { ...this.form, 'Placa': '', 'Frota': '', 'Ocorrência': '', 'Horário': '' }
		},
		saveFile(event) {
			this.file = event.target.files[0]
			this.isFile = true
		},
		changeInputType(fieldName) {
			this.$set(this.form, fieldName, '')
			this.$nextTick(() => {
				this.$refs[fieldName].type = 'date'
				this.$refs[fieldName].focus()
			});
		},
	},
}
</script>

<style lang="stylus" scoped src="./Form.styl"></style>
